import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  addUserRestrictionInReport,
  addUserShadowBanInReport,
  removeUserRestrictionInReport,
  removeUserShadowBanInReport,
} from './reportsReducer'
import {
  addUserRestrictionInPosts,
  addUserShadowBanInPosts,
  removeUserRestrictionInPosts,
  removeUserShadowBanInPosts,
} from './postsReducer'
import {
  addUserRestriction,
  createUserShadowBan,
  deleteUser,
  deleteUserShadowBan,
  getUserById,
  getUsers,
  removeUserRestriction,
  updateUser,
} from '../../api/userApi'
import { searchParamsSelector } from '../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer'
import { updateUserProperty } from '../../api/cleverTapApi'

const initialState = {
  users: [],
  searchItems: [],
  searchNextToken: '',
  searchFetching: false,
  activeUser: null,
  status: null,
  nextToken: '',
  fetching: false,
}

export const getUserThunk = createAsyncThunk('users/getUser', async (payload, { getState }) => {
  const response = await getUserById(payload)
  return response.data
})

export const getUsersThunk = createAsyncThunk('users/getUsers', async (payload, { getState }) => {
  const state = getState()
  const { searchString, gender, excludeEmptyNames, startTime, endTime, restrictionType, email, phone, id, tier } =
    searchParamsSelector(state)
  if (id) {
    const response = await getUserById(id)
    return {
      users: [response.data],
    }
  } else {
    const response = await getUsers({
      limit: 10,
      q: searchString,
      gender,
      excludeEmptyNames,
      startTime: startTime?.toISOString(),
      endTime: endTime?.toISOString(),
      restrictionType,
      email,
      phone,
      tier,
    })
    return response.data
  }
})

export const getUsersByNextToken = createAsyncThunk('users/getUsersByNextToken', async (payload, { getState }) => {
  const state = getState()
  const { searchString, gender, excludeEmptyNames, startTime, endTime, restrictionType, email, phone, tier } =
    searchParamsSelector(state)
  const response = await getUsers({
    q: searchString,
    gender,
    excludeEmptyNames,
    startTime: startTime?.toISOString(),
    endTime: endTime?.toISOString(),
    nextToken: payload?.nextToken,
    restrictionType,
    email,
    phone,
    tier,
  })
  return response.data
})

export const searchUsersThunk = createAsyncThunk('users/searchUsersThunk', async (payload, { getState }) => {
  const state = getState()
  const { searchString, gender, excludeEmptyNames, startTime, endTime, restrictionType, email, phone } =
    searchParamsSelector(state)
  const response = await getUsers({
    q: searchString,
    gender,
    excludeEmptyNames,
    startTime: startTime?.toISOString(),
    endTime: endTime?.toISOString(),
    restrictionType,
    email,
    phone,
  })
  return response.data
})

export const searchUsersByNextToken = createAsyncThunk(
  'users/searchUsersByNextToken',
  async (payload, { getState }) => {
    const state = getState()
    const { searchString, gender, excludeEmptyNames, startTime, endTime, restrictionType, email, phone } =
      searchParamsSelector(state)
    const response = await getUsers({
      q: searchString,
      gender,
      excludeEmptyNames,
      startTime: startTime?.toISOString(),
      endTime: endTime?.toISOString(),
      nextToken: payload?.nextToken,
      restrictionType,
      email,
      phone,
    })

    return response.data
  }
)

const delay = (s) => new Promise((res) => setTimeout(res, s * 1000))

export const updateUserThunk = createAsyncThunk('users/updateUser', async (payload, { dispatch, getState }) => {
  const user = selectActiveUser(getState())
  const restrictions = payload?.user?.restrictions
  if (restrictions) {
    const addedRestrictions = restrictions?.filter((item) => !user?.restrictions?.includes(item))
    const removedRestrictions = new Set(user?.restrictions?.filter((item) => !restrictions?.includes(item)))

    addedRestrictions?.forEach((restriction) => {
      dispatch(
        addUserRestrictionThunk({
          userId: payload.userId,
          restrictionType: restriction,
        })
      )
    })
    removedRestrictions?.forEach((restriction) => {
      dispatch(
        removeUserRestrictionThunk({
          userId: payload.userId,
          restrictionType: restriction,
        })
      )
    })
    await delay(0.5)
  }
  delete payload.user.restrictions
  if (Object.values(payload.user).filter((value) => value !== undefined).length === 0) {
    return selectActiveUser(getState())
  }

  const response = await updateUser(payload.user, payload.userId)
  return response.data
})

export const deleteUserThunk = createAsyncThunk('users/deleteUser', async (payload) => {
  await deleteUser(payload)
  return payload
})

export const createUserShadowBanThunk = createAsyncThunk(
  'users/createUserShadowBanThunk',
  async (payload, { dispatch }) => {
    const response = await createUserShadowBan(payload)
    if (response.status === 201) {
      dispatch(addUserShadowBanInReport(payload))
      dispatch(addUserShadowBanInPosts(payload))
    }
    return payload
  }
)

export const deleteUserShadowBanThunk = createAsyncThunk(
  'users/deleteUserShadowBanThunk',
  async (payload, { dispatch }) => {
    const response = await deleteUserShadowBan(payload)
    if (response.status === 204) {
      dispatch(removeUserShadowBanInReport(payload))
      dispatch(removeUserShadowBanInPosts(payload))
    }
    return payload
  }
)

export const updateUserTierThunk = createAsyncThunk('users/updateUserTierThunk', async (payload, { dispatch }) => {
  const { userId, tier } = payload
  await updateUser({ tier: tier.id }, userId)

  return payload
})

export const addUserRestrictionThunk = createAsyncThunk(
  'users/addUserRestrictionThunk',
  async ({ userId, restrictionType }, { dispatch }) => {
    const response = await addUserRestriction({
      userId,
      restrictionType,
    })

    if (response.status === 201) {
      if (restrictionType === 19) {
        await updateUserProperty(userId, 'cannotEarnPoints', 'true')
      }

      dispatch(
        addUserRestrictionInReport({
          restrictionType,
          userId,
        })
      )
      dispatch(
        addUserRestrictionInPosts({
          restrictionType,
          userId,
        })
      )
    }
    return restrictionType
  }
)

export const removeUserRestrictionThunk = createAsyncThunk(
  'users/removeUserRestrictionThunk',
  async ({ userId, restrictionType }, { dispatch }) => {
    const response = await removeUserRestriction({
      userId,
      restrictionType,
    })

    if (response.status === 204) {
      if (restrictionType === 19) {
        await updateUserProperty(userId, 'cannotEarnPoints', 'false')
      }

      dispatch(
        removeUserRestrictionInReport({
          restrictionType,
          userId,
        })
      )
      dispatch(
        removeUserRestrictionInPosts({
          restrictionType,
          userId,
        })
      )
    }

    return restrictionType
  }
)

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setActiveUser: (state, action) => {
      state.activeUser = action.payload
    },
    setFetching: (state, action) => {
      state.fetching = action.payload
    },
    setSearchFetching: (state, action) => {
      state.searchFetching = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersThunk.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getUsersThunk.fulfilled, (state, action) => {
      state.status = 'successfully'
      state.users = action.payload.users
      state.nextToken = action.payload.nextToken
    })
    builder.addCase(getUsersThunk.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(getUsersByNextToken.fulfilled, (state, action) => {
      state.status = 'successfully'
      if (action?.payload?.users) {
        state.users.push(...action.payload.users)
      }
      state.nextToken = action.payload.nextToken
      state.fetching = false
    })
    builder.addCase(searchUsersThunk.fulfilled, (state, action) => {
      state.searchItems = action.payload.users || []
      state.searchNextToken = action.payload.nextToken
    })
    builder.addCase(searchUsersByNextToken.fulfilled, (state, action) => {
      if (action?.payload?.users) {
        state.searchItems.push(...action.payload.users)
      }
      state.searchNextToken = action.payload.nextToken
      state.searchFetching = false
    })
    builder.addCase(getUserThunk.fulfilled, (state, action) => {
      state.activeUser = action.payload
    })
    builder.addCase(updateUserThunk.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(updateUserThunk.fulfilled, (state, action) => {
      state.status = 'successfullyUpdated'
      state.users = state.users.map((user) => {
        if (user.id === action.payload.id) return action.payload
        return user
      })
    })
    builder.addCase(updateUserThunk.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(deleteUserThunk.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(deleteUserThunk.fulfilled, (state, action) => {
      state.status = null
      state.users = state.users?.filter((user) => user.id !== action.payload)
    })
    builder.addCase(deleteUserThunk.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(createUserShadowBanThunk.fulfilled, (state, action) => {
      const user = state.users?.find((user) => user.id === action.payload)

      if (user) {
        user.isShadowBanned = true
      }

      if (state.activeUser) {
        state.activeUser.isShadowBanned = true
      }
    })
    builder.addCase(deleteUserShadowBanThunk.fulfilled, (state, action) => {
      const user = state.users?.find((user) => user.id === action.payload)

      if (user) {
        user.isShadowBanned = false
      }

      if (state.activeUser) {
        state.activeUser.isShadowBanned = false
      }
    })
    builder.addCase(addUserRestrictionThunk.fulfilled, (state, action) => {
      if (state.activeUser.restrictions) {
        state.activeUser.restrictions.push(action.payload)
      } else {
        state.activeUser.restrictions = [action.payload]
      }
      const user = state.users.find((user) => user.id === state.activeUser.id)
      user.restrictions = state.activeUser.restrictions

      state.users = state.users.map((user) => {
        if (user.id === state.activeUser.id) return state.activeUser
        return user
      })
    })
    builder.addCase(removeUserRestrictionThunk.fulfilled, (state, action) => {
      state.activeUser.restrictions = state.activeUser.restrictions.filter(
        (restriction) => restriction !== action.payload
      )
      const user = state.users.find((user) => user.id === state.activeUser.id)
      user.restrictions = state.activeUser.restrictions
      state.users = state.users.map((user) => {
        if (user.id === state.activeUser.id) return state.activeUser
        return user
      })
    })
    builder.addCase(updateUserTierThunk.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateUserTierThunk.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(updateUserTierThunk.fulfilled, (state, action) => {
      const { userId, tier } = action.payload

      state.status = null

      state.users = state.users.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            tier: tier.id,
          }
        }

        return user
      })
    })
  },
})

export default usersSlice.reducer
export const { setStatus, setActiveUser, setFetching, setSearchFetching } = usersSlice.actions

export const selectUsers = (state) => state.users.users

export const selectActiveUser = (state) => state.users.activeUser
export const selectNextToken = (state) => state.users.nextToken
export const selectFetching = (state) => state.users.fetching
export const selectStatus = (state) => state.users.status
export const selectSearchUsers = (state) => state.users.searchItems
export const selectSearchNextToken = (state) => state.users.searchNextToken
export const selectSearchFetching = (state) => state.users.searchFetching
